import React from 'react'
import { HostQuery, SocialPlatformEnum, ProgramTypeEnum } from '../../generated/graphql'
import styles from '../../App.module.css'
import { ReactComponent as InstagramIcon } from '../../icons/instagram.svg'
import { ReactComponent as TikTokIcon } from '../../icons/tiktok_logo_outline.svg'

interface CompleteFormProps {
  successAnimation: string
  hostData: HostQuery | null
  handleCloseModal: () => void
  ambassadorStorefrontUrls?: string[] | null
}

const CompleteForm = ({
  successAnimation,
  hostData,
  handleCloseModal,
  ambassadorStorefrontUrls,
}: CompleteFormProps): React.ReactElement => {
  const linkToAmbassadorStorefront =
    ambassadorStorefrontUrls &&
    ambassadorStorefrontUrls.length > 0 &&
    hostData?.programHost?.program?.autoApproved &&
    hostData?.programHost?.program?.programType === ProgramTypeEnum.ShoppableAmbassador

  /**
   * Event handlers
   */
  const handleTikTokClick = () => {
    if (hostData?.programHost?.username) {
      window.open(`https://www.tiktok.com/@${hostData?.programHost?.username}`, '_blank', 'noreferrer,noopener')
    }
    handleCloseModal()
  }
  const handleInstaClick = () => {
    if (hostData?.programHost?.username) {
      window.open(`https://www.instagram.com/${hostData?.programHost?.username}`, '_blank', 'noreferrer,noopener')
    }
    handleCloseModal()
  }
  const handleStorefrontClick = (url: string) => {
    if (url) {
      window.open(url, '_blank', 'noreferrer,noopener')
    }
    handleCloseModal()
  }

  return (
    <div>
      <div className={styles.loadingContainer}>
        <img src={successAnimation} className={styles.animationGif} alt="success" />
      </div>
      <div>
        {linkToAmbassadorStorefront ? (
          ambassadorStorefrontUrls?.map((url, i) => (
            <button key={`sfl_${i}`} onClick={() => handleStorefrontClick(url)} className={styles.instagramButton}>
              <span>Take me to my storefront</span>
            </button>
          ))
        ) : hostData?.programHost?.socialPlatform === SocialPlatformEnum.Tiktok ? (
          <button onClick={handleTikTokClick} className={styles.instagramButton}>
            <TikTokIcon className={styles.instagramIcon} />
            <span>Take me to TikTok</span>
          </button>
        ) : (
          <button onClick={handleInstaClick} className={styles.instagramButton}>
            <InstagramIcon className={styles.instagramIcon} />
            <span>Take me to Instagram</span>
          </button>
        )}
        <button onClick={handleCloseModal} className={styles.closeButton}>
          Close
        </button>
      </div>
    </div>
  )
}

export default CompleteForm
