export type DateParts = 'day' | 'month' | 'year'
export type DateOrder = [DateParts, DateParts, DateParts]

export function getLocaleDateOrder(): DateOrder {
  try {
    const testDate = new Date(2020, 0, 30)
    // print the date with the locale order, but latin numbers and using the gregorian calendar. this eliminated using the users language's number system or calendar, and just prints the order
    const testDateStr = testDate.toLocaleDateString(navigator.language, {
      numberingSystem: 'latn',
      calendar: 'gregory',
    })
    // tuple of the date part and where it falls in the test date string
    // so 'MM/DD/YYYY' would have:
    // orderingTup = [['year', 6], ['month', 3], ['day', 0]]
    const orderingTup = [
      ['year', testDateStr.indexOf('2020')] as const,
      ['month', testDateStr.indexOf('1')] as const,
      ['day', testDateStr.indexOf('30')] as const,
    ]
    // sort by where it falls in the string:
    // [['day', 0], ['month', 3], ['year', 6]]
    orderingTup.sort((a, b) => a[1] - b[1])

    // return a three elm tuple of the date part in locale order
    return orderingTup.map(v => v[0]) as DateOrder
  } catch {}
  return ['day', 'month', 'year']
}
