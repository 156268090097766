import { Country } from 'react-phone-number-input'

type CountriesAvailable = Exclude<Country, 'AC' | 'TA' | 'XK'>

// https://github.com/moment/moment-timezone/blob/develop/data/meta/latest.json
const COUNTRIES_WITH_TZ: {
  [K in CountriesAvailable]: {
    name: string
    abbr: string
    zones: string[]
  }
} = {
  AD: {
    name: 'Andorra',
    abbr: 'AD',
    zones: ['Europe/Andorra'],
  },
  AE: {
    name: 'United Arab Emirates',
    abbr: 'AE',
    zones: ['Asia/Dubai'],
  },
  AF: {
    name: 'Afghanistan',
    abbr: 'AF',
    zones: ['Asia/Kabul'],
  },
  AG: {
    name: 'Antigua & Barbuda',
    abbr: 'AG',
    zones: ['America/Puerto_Rico', 'America/Antigua'],
  },
  AI: {
    name: 'Anguilla',
    abbr: 'AI',
    zones: ['America/Puerto_Rico', 'America/Anguilla'],
  },
  AL: {
    name: 'Albania',
    abbr: 'AL',
    zones: ['Europe/Tirane'],
  },
  AM: {
    name: 'Armenia',
    abbr: 'AM',
    zones: ['Asia/Yerevan'],
  },
  AO: {
    name: 'Angola',
    abbr: 'AO',
    zones: ['Africa/Lagos', 'Africa/Luanda'],
  },
  AR: {
    name: 'Argentina',
    abbr: 'AR',
    zones: [
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Cordoba',
      'America/Argentina/Salta',
      'America/Argentina/Jujuy',
      'America/Argentina/Tucuman',
      'America/Argentina/Catamarca',
      'America/Argentina/La_Rioja',
      'America/Argentina/San_Juan',
      'America/Argentina/Mendoza',
      'America/Argentina/San_Luis',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Ushuaia',
    ],
  },
  AS: {
    name: 'Samoa (American)',
    abbr: 'AS',
    zones: ['Pacific/Pago_Pago'],
  },
  AT: {
    name: 'Austria',
    abbr: 'AT',
    zones: ['Europe/Vienna'],
  },
  AU: {
    name: 'Australia',
    abbr: 'AU',
    zones: [
      'Australia/Lord_Howe',
      'Antarctica/Macquarie',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Sydney',
      'Australia/Broken_Hill',
      'Australia/Brisbane',
      'Australia/Lindeman',
      'Australia/Adelaide',
      'Australia/Darwin',
      'Australia/Perth',
      'Australia/Eucla',
    ],
  },
  AW: {
    name: 'Aruba',
    abbr: 'AW',
    zones: ['America/Puerto_Rico', 'America/Aruba'],
  },
  AX: {
    name: 'Åland Islands',
    abbr: 'AX',
    zones: ['Europe/Helsinki', 'Europe/Mariehamn'],
  },
  AZ: {
    name: 'Azerbaijan',
    abbr: 'AZ',
    zones: ['Asia/Baku'],
  },
  BA: {
    name: 'Bosnia & Herzegovina',
    abbr: 'BA',
    zones: ['Europe/Belgrade', 'Europe/Sarajevo'],
  },
  BB: {
    name: 'Barbados',
    abbr: 'BB',
    zones: ['America/Barbados'],
  },
  BD: {
    name: 'Bangladesh',
    abbr: 'BD',
    zones: ['Asia/Dhaka'],
  },
  BE: {
    name: 'Belgium',
    abbr: 'BE',
    zones: ['Europe/Brussels'],
  },
  BF: {
    name: 'Burkina Faso',
    abbr: 'BF',
    zones: ['Africa/Abidjan', 'Africa/Ouagadougou'],
  },
  BG: {
    name: 'Bulgaria',
    abbr: 'BG',
    zones: ['Europe/Sofia'],
  },
  BH: {
    name: 'Bahrain',
    abbr: 'BH',
    zones: ['Asia/Qatar', 'Asia/Bahrain'],
  },
  BI: {
    name: 'Burundi',
    abbr: 'BI',
    zones: ['Africa/Maputo', 'Africa/Bujumbura'],
  },
  BJ: {
    name: 'Benin',
    abbr: 'BJ',
    zones: ['Africa/Lagos', 'Africa/Porto-Novo'],
  },
  BL: {
    name: 'St Barthelemy',
    abbr: 'BL',
    zones: ['America/Puerto_Rico', 'America/St_Barthelemy'],
  },
  BM: {
    name: 'Bermuda',
    abbr: 'BM',
    zones: ['Atlantic/Bermuda'],
  },
  BN: {
    name: 'Brunei',
    abbr: 'BN',
    zones: ['Asia/Kuching', 'Asia/Brunei'],
  },
  BO: {
    name: 'Bolivia',
    abbr: 'BO',
    zones: ['America/La_Paz'],
  },
  BQ: {
    name: 'Caribbean NL',
    abbr: 'BQ',
    zones: ['America/Puerto_Rico', 'America/Kralendijk'],
  },
  BR: {
    name: 'Brazil',
    abbr: 'BR',
    zones: [
      'America/Noronha',
      'America/Belem',
      'America/Fortaleza',
      'America/Recife',
      'America/Araguaina',
      'America/Maceio',
      'America/Bahia',
      'America/Sao_Paulo',
      'America/Campo_Grande',
      'America/Cuiaba',
      'America/Santarem',
      'America/Porto_Velho',
      'America/Boa_Vista',
      'America/Manaus',
      'America/Eirunepe',
      'America/Rio_Branco',
    ],
  },
  BS: {
    name: 'Bahamas',
    abbr: 'BS',
    zones: ['America/Toronto', 'America/Nassau'],
  },
  BT: {
    name: 'Bhutan',
    abbr: 'BT',
    zones: ['Asia/Thimphu'],
  },
  BW: {
    name: 'Botswana',
    abbr: 'BW',
    zones: ['Africa/Maputo', 'Africa/Gaborone'],
  },
  BY: {
    name: 'Belarus',
    abbr: 'BY',
    zones: ['Europe/Minsk'],
  },
  BZ: {
    name: 'Belize',
    abbr: 'BZ',
    zones: ['America/Belize'],
  },
  CA: {
    name: 'Canada',
    abbr: 'CA',
    zones: [
      'America/St_Johns',
      'America/Halifax',
      'America/Glace_Bay',
      'America/Moncton',
      'America/Goose_Bay',
      'America/Toronto',
      'America/Nipigon',
      'America/Thunder_Bay',
      'America/Iqaluit',
      'America/Pangnirtung',
      'America/Winnipeg',
      'America/Rainy_River',
      'America/Resolute',
      'America/Rankin_Inlet',
      'America/Regina',
      'America/Swift_Current',
      'America/Edmonton',
      'America/Cambridge_Bay',
      'America/Yellowknife',
      'America/Inuvik',
      'America/Dawson_Creek',
      'America/Fort_Nelson',
      'America/Whitehorse',
      'America/Dawson',
      'America/Vancouver',
      'America/Panama',
      'America/Puerto_Rico',
      'America/Phoenix',
      'America/Blanc-Sablon',
      'America/Atikokan',
      'America/Creston',
    ],
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    abbr: 'CC',
    zones: ['Asia/Yangon', 'Indian/Cocos'],
  },
  CD: {
    name: 'Congo (Dem. Rep.)',
    abbr: 'CD',
    zones: ['Africa/Maputo', 'Africa/Lagos', 'Africa/Kinshasa', 'Africa/Lubumbashi'],
  },
  CF: {
    name: 'Central African Rep.',
    abbr: 'CF',
    zones: ['Africa/Lagos', 'Africa/Bangui'],
  },
  CG: {
    name: 'Congo (Rep.)',
    abbr: 'CG',
    zones: ['Africa/Lagos', 'Africa/Brazzaville'],
  },
  CH: {
    name: 'Switzerland',
    abbr: 'CH',
    zones: ['Europe/Zurich'],
  },
  CI: {
    name: "Côte d'Ivoire",
    abbr: 'CI',
    zones: ['Africa/Abidjan'],
  },
  CK: {
    name: 'Cook Islands',
    abbr: 'CK',
    zones: ['Pacific/Rarotonga'],
  },
  CL: {
    name: 'Chile',
    abbr: 'CL',
    zones: ['America/Santiago', 'America/Punta_Arenas', 'Pacific/Easter'],
  },
  CM: {
    name: 'Cameroon',
    abbr: 'CM',
    zones: ['Africa/Lagos', 'Africa/Douala'],
  },
  CN: {
    name: 'China',
    abbr: 'CN',
    zones: ['Asia/Shanghai', 'Asia/Urumqi'],
  },
  CO: {
    name: 'Colombia',
    abbr: 'CO',
    zones: ['America/Bogota'],
  },
  CR: {
    name: 'Costa Rica',
    abbr: 'CR',
    zones: ['America/Costa_Rica'],
  },
  CU: {
    name: 'Cuba',
    abbr: 'CU',
    zones: ['America/Havana'],
  },
  CV: {
    name: 'Cape Verde',
    abbr: 'CV',
    zones: ['Atlantic/Cape_Verde'],
  },
  CW: {
    name: 'Curaçao',
    abbr: 'CW',
    zones: ['America/Puerto_Rico', 'America/Curacao'],
  },
  CX: {
    name: 'Christmas Island',
    abbr: 'CX',
    zones: ['Asia/Bangkok', 'Indian/Christmas'],
  },
  CY: {
    name: 'Cyprus',
    abbr: 'CY',
    zones: ['Asia/Nicosia', 'Asia/Famagusta'],
  },
  CZ: {
    name: 'Czech Republic',
    abbr: 'CZ',
    zones: ['Europe/Prague'],
  },
  DE: {
    name: 'Germany',
    abbr: 'DE',
    zones: ['Europe/Zurich', 'Europe/Berlin', 'Europe/Busingen'],
  },
  DJ: {
    name: 'Djibouti',
    abbr: 'DJ',
    zones: ['Africa/Nairobi', 'Africa/Djibouti'],
  },
  DK: {
    name: 'Denmark',
    abbr: 'DK',
    zones: ['Europe/Berlin', 'Europe/Copenhagen'],
  },
  DM: {
    name: 'Dominica',
    abbr: 'DM',
    zones: ['America/Puerto_Rico', 'America/Dominica'],
  },
  DO: {
    name: 'Dominican Republic',
    abbr: 'DO',
    zones: ['America/Santo_Domingo'],
  },
  DZ: {
    name: 'Algeria',
    abbr: 'DZ',
    zones: ['Africa/Algiers'],
  },
  EC: {
    name: 'Ecuador',
    abbr: 'EC',
    zones: ['America/Guayaquil', 'Pacific/Galapagos'],
  },
  EE: {
    name: 'Estonia',
    abbr: 'EE',
    zones: ['Europe/Tallinn'],
  },
  EG: {
    name: 'Egypt',
    abbr: 'EG',
    zones: ['Africa/Cairo'],
  },
  EH: {
    name: 'Western Sahara',
    abbr: 'EH',
    zones: ['Africa/El_Aaiun'],
  },
  ER: {
    name: 'Eritrea',
    abbr: 'ER',
    zones: ['Africa/Nairobi', 'Africa/Asmara'],
  },
  ES: {
    name: 'Spain',
    abbr: 'ES',
    zones: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
  },
  ET: {
    name: 'Ethiopia',
    abbr: 'ET',
    zones: ['Africa/Nairobi', 'Africa/Addis_Ababa'],
  },
  FI: {
    name: 'Finland',
    abbr: 'FI',
    zones: ['Europe/Helsinki'],
  },
  FJ: {
    name: 'Fiji',
    abbr: 'FJ',
    zones: ['Pacific/Fiji'],
  },
  FK: {
    name: 'Falkland Islands',
    abbr: 'FK',
    zones: ['Atlantic/Stanley'],
  },
  FM: {
    name: 'Micronesia',
    abbr: 'FM',
    zones: ['Pacific/Kosrae', 'Pacific/Port_Moresby', 'Pacific/Guadalcanal', 'Pacific/Chuuk', 'Pacific/Pohnpei'],
  },
  FO: {
    name: 'Faroe Islands',
    abbr: 'FO',
    zones: ['Atlantic/Faroe'],
  },
  FR: {
    name: 'France',
    abbr: 'FR',
    zones: ['Europe/Paris'],
  },
  GA: {
    name: 'Gabon',
    abbr: 'GA',
    zones: ['Africa/Lagos', 'Africa/Libreville'],
  },
  GB: {
    name: 'Britain (UK)',
    abbr: 'GB',
    zones: ['Europe/London'],
  },
  GD: {
    name: 'Grenada',
    abbr: 'GD',
    zones: ['America/Puerto_Rico', 'America/Grenada'],
  },
  GE: {
    name: 'Georgia',
    abbr: 'GE',
    zones: ['Asia/Tbilisi'],
  },
  GF: {
    name: 'French Guiana',
    abbr: 'GF',
    zones: ['America/Cayenne'],
  },
  GG: {
    name: 'Guernsey',
    abbr: 'GG',
    zones: ['Europe/London', 'Europe/Guernsey'],
  },
  GH: {
    name: 'Ghana',
    abbr: 'GH',
    zones: ['Africa/Abidjan', 'Africa/Accra'],
  },
  GI: {
    name: 'Gibraltar',
    abbr: 'GI',
    zones: ['Europe/Gibraltar'],
  },
  GL: {
    name: 'Greenland',
    abbr: 'GL',
    zones: ['America/Nuuk', 'America/Danmarkshavn', 'America/Scoresbysund', 'America/Thule'],
  },
  GM: {
    name: 'Gambia',
    abbr: 'GM',
    zones: ['Africa/Abidjan', 'Africa/Banjul'],
  },
  GN: {
    name: 'Guinea',
    abbr: 'GN',
    zones: ['Africa/Abidjan', 'Africa/Conakry'],
  },
  GP: {
    name: 'Guadeloupe',
    abbr: 'GP',
    zones: ['America/Puerto_Rico', 'America/Guadeloupe'],
  },
  GQ: {
    name: 'Equatorial Guinea',
    abbr: 'GQ',
    zones: ['Africa/Lagos', 'Africa/Malabo'],
  },
  GR: {
    name: 'Greece',
    abbr: 'GR',
    zones: ['Europe/Athens'],
  },
  GT: {
    name: 'Guatemala',
    abbr: 'GT',
    zones: ['America/Guatemala'],
  },
  GU: {
    name: 'Guam',
    abbr: 'GU',
    zones: ['Pacific/Guam'],
  },
  GW: {
    name: 'Guinea-Bissau',
    abbr: 'GW',
    zones: ['Africa/Bissau'],
  },
  GY: {
    name: 'Guyana',
    abbr: 'GY',
    zones: ['America/Guyana'],
  },
  HK: {
    name: 'Hong Kong',
    abbr: 'HK',
    zones: ['Asia/Hong_Kong'],
  },
  HN: {
    name: 'Honduras',
    abbr: 'HN',
    zones: ['America/Tegucigalpa'],
  },
  HR: {
    name: 'Croatia',
    abbr: 'HR',
    zones: ['Europe/Belgrade', 'Europe/Zagreb'],
  },
  HT: {
    name: 'Haiti',
    abbr: 'HT',
    zones: ['America/Port-au-Prince'],
  },
  HU: {
    name: 'Hungary',
    abbr: 'HU',
    zones: ['Europe/Budapest'],
  },
  ID: {
    name: 'Indonesia',
    abbr: 'ID',
    zones: ['Asia/Jakarta', 'Asia/Pontianak', 'Asia/Makassar', 'Asia/Jayapura'],
  },
  IE: {
    name: 'Ireland',
    abbr: 'IE',
    zones: ['Europe/Dublin'],
  },
  IL: {
    name: 'Israel',
    abbr: 'IL',
    zones: ['Asia/Jerusalem'],
  },
  IM: {
    name: 'Isle of Man',
    abbr: 'IM',
    zones: ['Europe/London', 'Europe/Isle_of_Man'],
  },
  IN: {
    name: 'India',
    abbr: 'IN',
    zones: ['Asia/Kolkata'],
  },
  IO: {
    name: 'British Indian Ocean Territory',
    abbr: 'IO',
    zones: ['Indian/Chagos'],
  },
  IQ: {
    name: 'Iraq',
    abbr: 'IQ',
    zones: ['Asia/Baghdad'],
  },
  IR: {
    name: 'Iran',
    abbr: 'IR',
    zones: ['Asia/Tehran'],
  },
  IS: {
    name: 'Iceland',
    abbr: 'IS',
    zones: ['Africa/Abidjan', 'Atlantic/Reykjavik'],
  },
  IT: {
    name: 'Italy',
    abbr: 'IT',
    zones: ['Europe/Rome'],
  },
  JE: {
    name: 'Jersey',
    abbr: 'JE',
    zones: ['Europe/London', 'Europe/Jersey'],
  },
  JM: {
    name: 'Jamaica',
    abbr: 'JM',
    zones: ['America/Jamaica'],
  },
  JO: {
    name: 'Jordan',
    abbr: 'JO',
    zones: ['Asia/Amman'],
  },
  JP: {
    name: 'Japan',
    abbr: 'JP',
    zones: ['Asia/Tokyo'],
  },
  KE: {
    name: 'Kenya',
    abbr: 'KE',
    zones: ['Africa/Nairobi'],
  },
  KG: {
    name: 'Kyrgyzstan',
    abbr: 'KG',
    zones: ['Asia/Bishkek'],
  },
  KH: {
    name: 'Cambodia',
    abbr: 'KH',
    zones: ['Asia/Bangkok', 'Asia/Phnom_Penh'],
  },
  KI: {
    name: 'Kiribati',
    abbr: 'KI',
    zones: ['Pacific/Tarawa', 'Pacific/Kanton', 'Pacific/Kiritimati'],
  },
  KM: {
    name: 'Comoros',
    abbr: 'KM',
    zones: ['Africa/Nairobi', 'Indian/Comoro'],
  },
  KN: {
    name: 'St Kitts & Nevis',
    abbr: 'KN',
    zones: ['America/Puerto_Rico', 'America/St_Kitts'],
  },
  KP: {
    name: 'Korea (North)',
    abbr: 'KP',
    zones: ['Asia/Pyongyang'],
  },
  KR: {
    name: 'Korea (South)',
    abbr: 'KR',
    zones: ['Asia/Seoul'],
  },
  KW: {
    name: 'Kuwait',
    abbr: 'KW',
    zones: ['Asia/Riyadh', 'Asia/Kuwait'],
  },
  KY: {
    name: 'Cayman Islands',
    abbr: 'KY',
    zones: ['America/Panama', 'America/Cayman'],
  },
  KZ: {
    name: 'Kazakhstan',
    abbr: 'KZ',
    zones: ['Asia/Almaty', 'Asia/Qyzylorda', 'Asia/Qostanay', 'Asia/Aqtobe', 'Asia/Aqtau', 'Asia/Atyrau', 'Asia/Oral'],
  },
  LA: {
    name: 'Laos',
    abbr: 'LA',
    zones: ['Asia/Bangkok', 'Asia/Vientiane'],
  },
  LB: {
    name: 'Lebanon',
    abbr: 'LB',
    zones: ['Asia/Beirut'],
  },
  LC: {
    name: 'St Lucia',
    abbr: 'LC',
    zones: ['America/Puerto_Rico', 'America/St_Lucia'],
  },
  LI: {
    name: 'Liechtenstein',
    abbr: 'LI',
    zones: ['Europe/Zurich', 'Europe/Vaduz'],
  },
  LK: {
    name: 'Sri Lanka',
    abbr: 'LK',
    zones: ['Asia/Colombo'],
  },
  LR: {
    name: 'Liberia',
    abbr: 'LR',
    zones: ['Africa/Monrovia'],
  },
  LS: {
    name: 'Lesotho',
    abbr: 'LS',
    zones: ['Africa/Johannesburg', 'Africa/Maseru'],
  },
  LT: {
    name: 'Lithuania',
    abbr: 'LT',
    zones: ['Europe/Vilnius'],
  },
  LU: {
    name: 'Luxembourg',
    abbr: 'LU',
    zones: ['Europe/Brussels', 'Europe/Luxembourg'],
  },
  LV: {
    name: 'Latvia',
    abbr: 'LV',
    zones: ['Europe/Riga'],
  },
  LY: {
    name: 'Libya',
    abbr: 'LY',
    zones: ['Africa/Tripoli'],
  },
  MA: {
    name: 'Morocco',
    abbr: 'MA',
    zones: ['Africa/Casablanca'],
  },
  MC: {
    name: 'Monaco',
    abbr: 'MC',
    zones: ['Europe/Paris', 'Europe/Monaco'],
  },
  MD: {
    name: 'Moldova',
    abbr: 'MD',
    zones: ['Europe/Chisinau'],
  },
  ME: {
    name: 'Montenegro',
    abbr: 'ME',
    zones: ['Europe/Belgrade', 'Europe/Podgorica'],
  },
  MF: {
    name: 'St Martin (French)',
    abbr: 'MF',
    zones: ['America/Puerto_Rico', 'America/Marigot'],
  },
  MG: {
    name: 'Madagascar',
    abbr: 'MG',
    zones: ['Africa/Nairobi', 'Indian/Antananarivo'],
  },
  MH: {
    name: 'Marshall Islands',
    abbr: 'MH',
    zones: ['Pacific/Tarawa', 'Pacific/Kwajalein', 'Pacific/Majuro'],
  },
  MK: {
    name: 'North Macedonia',
    abbr: 'MK',
    zones: ['Europe/Belgrade', 'Europe/Skopje'],
  },
  ML: {
    name: 'Mali',
    abbr: 'ML',
    zones: ['Africa/Abidjan', 'Africa/Bamako'],
  },
  MM: {
    name: 'Myanmar (Burma)',
    abbr: 'MM',
    zones: ['Asia/Yangon'],
  },
  MN: {
    name: 'Mongolia',
    abbr: 'MN',
    zones: ['Asia/Ulaanbaatar', 'Asia/Hovd', 'Asia/Choibalsan'],
  },
  MO: {
    name: 'Macau',
    abbr: 'MO',
    zones: ['Asia/Macau'],
  },
  MP: {
    name: 'Northern Mariana Islands',
    abbr: 'MP',
    zones: ['Pacific/Guam', 'Pacific/Saipan'],
  },
  MQ: {
    name: 'Martinique',
    abbr: 'MQ',
    zones: ['America/Martinique'],
  },
  MR: {
    name: 'Mauritania',
    abbr: 'MR',
    zones: ['Africa/Abidjan', 'Africa/Nouakchott'],
  },
  MS: {
    name: 'Montserrat',
    abbr: 'MS',
    zones: ['America/Puerto_Rico', 'America/Montserrat'],
  },
  MT: {
    name: 'Malta',
    abbr: 'MT',
    zones: ['Europe/Malta'],
  },
  MU: {
    name: 'Mauritius',
    abbr: 'MU',
    zones: ['Indian/Mauritius'],
  },
  MV: {
    name: 'Maldives',
    abbr: 'MV',
    zones: ['Indian/Maldives'],
  },
  MW: {
    name: 'Malawi',
    abbr: 'MW',
    zones: ['Africa/Maputo', 'Africa/Blantyre'],
  },
  MX: {
    name: 'Mexico',
    abbr: 'MX',
    zones: [
      'America/Mexico_City',
      'America/Cancun',
      'America/Merida',
      'America/Monterrey',
      'America/Matamoros',
      'America/Mazatlan',
      'America/Chihuahua',
      'America/Ojinaga',
      'America/Hermosillo',
      'America/Tijuana',
      'America/Bahia_Banderas',
    ],
  },
  MY: {
    name: 'Malaysia',
    abbr: 'MY',
    zones: ['Asia/Kuching', 'Asia/Singapore', 'Asia/Kuala_Lumpur'],
  },
  MZ: {
    name: 'Mozambique',
    abbr: 'MZ',
    zones: ['Africa/Maputo'],
  },
  NA: {
    name: 'Namibia',
    abbr: 'NA',
    zones: ['Africa/Windhoek'],
  },
  NC: {
    name: 'New Caledonia',
    abbr: 'NC',
    zones: ['Pacific/Noumea'],
  },
  NE: {
    name: 'Niger',
    abbr: 'NE',
    zones: ['Africa/Lagos', 'Africa/Niamey'],
  },
  NF: {
    name: 'Norfolk Island',
    abbr: 'NF',
    zones: ['Pacific/Norfolk'],
  },
  NG: {
    name: 'Nigeria',
    abbr: 'NG',
    zones: ['Africa/Lagos'],
  },
  NI: {
    name: 'Nicaragua',
    abbr: 'NI',
    zones: ['America/Managua'],
  },
  NL: {
    name: 'Netherlands',
    abbr: 'NL',
    zones: ['Europe/Brussels', 'Europe/Amsterdam'],
  },
  NO: {
    name: 'Norway',
    abbr: 'NO',
    zones: ['Europe/Berlin', 'Europe/Oslo'],
  },
  NP: {
    name: 'Nepal',
    abbr: 'NP',
    zones: ['Asia/Kathmandu'],
  },
  NR: {
    name: 'Nauru',
    abbr: 'NR',
    zones: ['Pacific/Nauru'],
  },
  NU: {
    name: 'Niue',
    abbr: 'NU',
    zones: ['Pacific/Niue'],
  },
  NZ: {
    name: 'New Zealand',
    abbr: 'NZ',
    zones: ['Pacific/Auckland', 'Pacific/Chatham'],
  },
  OM: {
    name: 'Oman',
    abbr: 'OM',
    zones: ['Asia/Dubai', 'Asia/Muscat'],
  },
  PA: {
    name: 'Panama',
    abbr: 'PA',
    zones: ['America/Panama'],
  },
  PE: {
    name: 'Peru',
    abbr: 'PE',
    zones: ['America/Lima'],
  },
  PF: {
    name: 'French Polynesia',
    abbr: 'PF',
    zones: ['Pacific/Tahiti', 'Pacific/Marquesas', 'Pacific/Gambier'],
  },
  PG: {
    name: 'Papua New Guinea',
    abbr: 'PG',
    zones: ['Pacific/Port_Moresby', 'Pacific/Bougainville'],
  },
  PH: {
    name: 'Philippines',
    abbr: 'PH',
    zones: ['Asia/Manila'],
  },
  PK: {
    name: 'Pakistan',
    abbr: 'PK',
    zones: ['Asia/Karachi'],
  },
  PL: {
    name: 'Poland',
    abbr: 'PL',
    zones: ['Europe/Warsaw'],
  },
  PM: {
    name: 'St Pierre & Miquelon',
    abbr: 'PM',
    zones: ['America/Miquelon'],
  },
  PR: {
    name: 'Puerto Rico',
    abbr: 'PR',
    zones: ['America/Puerto_Rico'],
  },
  PS: {
    name: 'Palestine',
    abbr: 'PS',
    zones: ['Asia/Gaza', 'Asia/Hebron'],
  },
  PT: {
    name: 'Portugal',
    abbr: 'PT',
    zones: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
  },
  PW: {
    name: 'Palau',
    abbr: 'PW',
    zones: ['Pacific/Palau'],
  },
  PY: {
    name: 'Paraguay',
    abbr: 'PY',
    zones: ['America/Asuncion'],
  },
  QA: {
    name: 'Qatar',
    abbr: 'QA',
    zones: ['Asia/Qatar'],
  },
  RE: {
    name: 'Réunion',
    abbr: 'RE',
    zones: ['Asia/Dubai', 'Indian/Reunion'],
  },
  RO: {
    name: 'Romania',
    abbr: 'RO',
    zones: ['Europe/Bucharest'],
  },
  RS: {
    name: 'Serbia',
    abbr: 'RS',
    zones: ['Europe/Belgrade'],
  },
  RU: {
    name: 'Russia',
    abbr: 'RU',
    zones: [
      'Europe/Kaliningrad',
      'Europe/Moscow',
      'Europe/Simferopol',
      'Europe/Kirov',
      'Europe/Volgograd',
      'Europe/Astrakhan',
      'Europe/Saratov',
      'Europe/Ulyanovsk',
      'Europe/Samara',
      'Asia/Yekaterinburg',
      'Asia/Omsk',
      'Asia/Novosibirsk',
      'Asia/Barnaul',
      'Asia/Tomsk',
      'Asia/Novokuznetsk',
      'Asia/Krasnoyarsk',
      'Asia/Irkutsk',
      'Asia/Chita',
      'Asia/Yakutsk',
      'Asia/Khandyga',
      'Asia/Vladivostok',
      'Asia/Ust-Nera',
      'Asia/Magadan',
      'Asia/Sakhalin',
      'Asia/Srednekolymsk',
      'Asia/Kamchatka',
      'Asia/Anadyr',
    ],
  },
  RW: {
    name: 'Rwanda',
    abbr: 'RW',
    zones: ['Africa/Maputo', 'Africa/Kigali'],
  },
  SA: {
    name: 'Saudi Arabia',
    abbr: 'SA',
    zones: ['Asia/Riyadh'],
  },
  SB: {
    name: 'Solomon Islands',
    abbr: 'SB',
    zones: ['Pacific/Guadalcanal'],
  },
  SC: {
    name: 'Seychelles',
    abbr: 'SC',
    zones: ['Asia/Dubai', 'Indian/Mahe'],
  },
  SD: {
    name: 'Sudan',
    abbr: 'SD',
    zones: ['Africa/Khartoum'],
  },
  SE: {
    name: 'Sweden',
    abbr: 'SE',
    zones: ['Europe/Berlin', 'Europe/Stockholm'],
  },
  SG: {
    name: 'Singapore',
    abbr: 'SG',
    zones: ['Asia/Singapore'],
  },
  SH: {
    name: 'St Helena',
    abbr: 'SH',
    zones: ['Africa/Abidjan', 'Atlantic/St_Helena'],
  },
  SI: {
    name: 'Slovenia',
    abbr: 'SI',
    zones: ['Europe/Belgrade', 'Europe/Ljubljana'],
  },
  SJ: {
    name: 'Svalbard & Jan Mayen',
    abbr: 'SJ',
    zones: ['Europe/Berlin', 'Arctic/Longyearbyen'],
  },
  SK: {
    name: 'Slovakia',
    abbr: 'SK',
    zones: ['Europe/Prague', 'Europe/Bratislava'],
  },
  SL: {
    name: 'Sierra Leone',
    abbr: 'SL',
    zones: ['Africa/Abidjan', 'Africa/Freetown'],
  },
  SM: {
    name: 'San Marino',
    abbr: 'SM',
    zones: ['Europe/Rome', 'Europe/San_Marino'],
  },
  SN: {
    name: 'Senegal',
    abbr: 'SN',
    zones: ['Africa/Abidjan', 'Africa/Dakar'],
  },
  SO: {
    name: 'Somalia',
    abbr: 'SO',
    zones: ['Africa/Nairobi', 'Africa/Mogadishu'],
  },
  SR: {
    name: 'Suriname',
    abbr: 'SR',
    zones: ['America/Paramaribo'],
  },
  SS: {
    name: 'South Sudan',
    abbr: 'SS',
    zones: ['Africa/Juba'],
  },
  ST: {
    name: 'Sao Tome & Principe',
    abbr: 'ST',
    zones: ['Africa/Sao_Tome'],
  },
  SV: {
    name: 'El Salvador',
    abbr: 'SV',
    zones: ['America/El_Salvador'],
  },
  SX: {
    name: 'St Maarten (Dutch)',
    abbr: 'SX',
    zones: ['America/Puerto_Rico', 'America/Lower_Princes'],
  },
  SY: {
    name: 'Syria',
    abbr: 'SY',
    zones: ['Asia/Damascus'],
  },
  SZ: {
    name: 'Eswatini (Swaziland)',
    abbr: 'SZ',
    zones: ['Africa/Johannesburg', 'Africa/Mbabane'],
  },
  TC: {
    name: 'Turks & Caicos Is',
    abbr: 'TC',
    zones: ['America/Grand_Turk'],
  },
  TD: {
    name: 'Chad',
    abbr: 'TD',
    zones: ['Africa/Ndjamena'],
  },
  TG: {
    name: 'Togo',
    abbr: 'TG',
    zones: ['Africa/Abidjan', 'Africa/Lome'],
  },
  TH: {
    name: 'Thailand',
    abbr: 'TH',
    zones: ['Asia/Bangkok'],
  },
  TJ: {
    name: 'Tajikistan',
    abbr: 'TJ',
    zones: ['Asia/Dushanbe'],
  },
  TK: {
    name: 'Tokelau',
    abbr: 'TK',
    zones: ['Pacific/Fakaofo'],
  },
  TL: {
    name: 'East Timor',
    abbr: 'TL',
    zones: ['Asia/Dili'],
  },
  TM: {
    name: 'Turkmenistan',
    abbr: 'TM',
    zones: ['Asia/Ashgabat'],
  },
  TN: {
    name: 'Tunisia',
    abbr: 'TN',
    zones: ['Africa/Tunis'],
  },
  TO: {
    name: 'Tonga',
    abbr: 'TO',
    zones: ['Pacific/Tongatapu'],
  },
  TR: {
    name: 'Turkey',
    abbr: 'TR',
    zones: ['Europe/Istanbul'],
  },
  TT: {
    name: 'Trinidad & Tobago',
    abbr: 'TT',
    zones: ['America/Puerto_Rico', 'America/Port_of_Spain'],
  },
  TV: {
    name: 'Tuvalu',
    abbr: 'TV',
    zones: ['Pacific/Tarawa', 'Pacific/Funafuti'],
  },
  TW: {
    name: 'Taiwan',
    abbr: 'TW',
    zones: ['Asia/Taipei'],
  },
  TZ: {
    name: 'Tanzania',
    abbr: 'TZ',
    zones: ['Africa/Nairobi', 'Africa/Dar_es_Salaam'],
  },
  UA: {
    name: 'Ukraine',
    abbr: 'UA',
    zones: ['Europe/Simferopol', 'Europe/Kyiv', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
  },
  UG: {
    name: 'Uganda',
    abbr: 'UG',
    zones: ['Africa/Nairobi', 'Africa/Kampala'],
  },
  US: {
    name: 'United States',
    abbr: 'US',
    zones: [
      'America/New_York',
      'America/Detroit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Indiana/Indianapolis',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Chicago',
      'America/Indiana/Tell_City',
      'America/Indiana/Knox',
      'America/Menominee',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/North_Dakota/Beulah',
      'America/Denver',
      'America/Boise',
      'America/Phoenix',
      'America/Los_Angeles',
      'America/Anchorage',
      'America/Juneau',
      'America/Sitka',
      'America/Metlakatla',
      'America/Yakutat',
      'America/Nome',
      'America/Adak',
      'Pacific/Honolulu',
    ],
  },
  UY: {
    name: 'Uruguay',
    abbr: 'UY',
    zones: ['America/Montevideo'],
  },
  UZ: {
    name: 'Uzbekistan',
    abbr: 'UZ',
    zones: ['Asia/Samarkand', 'Asia/Tashkent'],
  },
  VA: {
    name: 'Vatican City',
    abbr: 'VA',
    zones: ['Europe/Rome', 'Europe/Vatican'],
  },
  VC: {
    name: 'St Vincent',
    abbr: 'VC',
    zones: ['America/Puerto_Rico', 'America/St_Vincent'],
  },
  VE: {
    name: 'Venezuela',
    abbr: 'VE',
    zones: ['America/Caracas'],
  },
  VG: {
    name: 'Virgin Islands (UK)',
    abbr: 'VG',
    zones: ['America/Puerto_Rico', 'America/Tortola'],
  },
  VI: {
    name: 'Virgin Islands (US)',
    abbr: 'VI',
    zones: ['America/Puerto_Rico', 'America/St_Thomas'],
  },
  VN: {
    name: 'Vietnam',
    abbr: 'VN',
    zones: ['Asia/Bangkok', 'Asia/Ho_Chi_Minh'],
  },
  VU: {
    name: 'Vanuatu',
    abbr: 'VU',
    zones: ['Pacific/Efate'],
  },
  WF: {
    name: 'Wallis & Futuna',
    abbr: 'WF',
    zones: ['Pacific/Tarawa', 'Pacific/Wallis'],
  },
  WS: {
    name: 'Samoa (western)',
    abbr: 'WS',
    zones: ['Pacific/Apia'],
  },
  YE: {
    name: 'Yemen',
    abbr: 'YE',
    zones: ['Asia/Riyadh', 'Asia/Aden'],
  },
  YT: {
    name: 'Mayotte',
    abbr: 'YT',
    zones: ['Africa/Nairobi', 'Indian/Mayotte'],
  },
  ZA: {
    name: 'South Africa',
    abbr: 'ZA',
    zones: ['Africa/Johannesburg'],
  },
  ZM: {
    name: 'Zambia',
    abbr: 'ZM',
    zones: ['Africa/Maputo', 'Africa/Lusaka'],
  },
  ZW: {
    name: 'Zimbabwe',
    abbr: 'ZW',
    zones: ['Africa/Maputo', 'Africa/Harare'],
  },
}

export const getCountryByCurrentTz = (): CountriesAvailable => {
  const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone

  const countryCode =
    Object.keys(COUNTRIES_WITH_TZ).find(code =>
      COUNTRIES_WITH_TZ[code as CountriesAvailable].zones.includes(currentTz),
    ) || 'US'

  return countryCode as CountriesAvailable
}
