/* Note this file is also distributed to PJDW for their auth.
 * Use caution when importing other files into this one, or change the api of the exports as that will impact the PJDW sdk.
 * If you do change something here, build the sdk `npm run build:fbsdk` and inspect the changes
 */

declare global {
  interface Window {
    fbAsyncInit: () => void
  }
}

interface Node {
  id: string
}

interface FBAccount extends Node {
  instagram_business_account?: Node
}

type FBAccountWithInstagram = Required<FBAccount>

interface FBAccountsResponse {
  data: FBAccount[]
}

export interface Token {
  value: string
}

export interface InstagramAccount {
  platformId: string
  username?: string
}

export interface SuccessAuthResponse {
  status: 'connected'
  token: Token
  platformId: string
  instagramAccounts: InstagramAccount[]
}

export interface NotLoggedInResponse {
  status: 'not_authorized' | 'unknown' | 'authorization_expired'
}

export interface ErrorResponses {
  status: 'error'
  error: unknown
}

export type AuthResponse = SuccessAuthResponse | NotLoggedInResponse | ErrorResponses

const defaultScopes = [
  'email',
  'public_profile',
  'instagram_basic',
  'instagram_manage_insights',
  'pages_read_engagement',
  'pages_show_list',
]
const id = 'facebook-jssdk'

/**
 * A class to manage users with facebook login, pull facebook data used by
 * LoudCrowd to sync their instagram business accounts. Use this class
 * to initialize the facebook sdk and open up a facebook login popup.
 *
 * example usage:
 * ```ts
 * import facebookSDK from 'lc-facebook-sdk'
 *
 * facebookSDK.init('a23j4kj23').then(() => {
 *   showFacebookLoginButton()
 * })
 *
 * onClickFacebookLoginButton = (event) => {
 *   facebookSDK.authInstagramAccounts().then((authResponse) => {
 *      if (authResponse.status === 'connected') {
 *        sendTokenToLC(authResponse)
 *      } else if (authResponse.status === 'error') {
 *        handleFBApiErrors(authResponse.error)
 *      } else if (authResponse.status === 'not_authorized') {
 *        handleUserDidNotGivePermissionForAppErrors()
 *      } else {
 *        handleUserDidNotLoginToFacebookError()
 *      }
 *   })
 * }
 *
 * ```
 */
let loaded = false
class FacebookSDK {
  /**
   * Initialize the sdk and query facebook for current login status.
   * Note: this function is async
   *
   * @param appId - facebook app id
   * @returns a promise the will resolve with the current users facebook login status once the sdk is initialized
   */
  init(appId: string): Promise<facebook.StatusResponse> {
    if (loaded) {
      return this.fbLoginStatus()
    } else {
      return new Promise((resolve) => {
        window.fbAsyncInit = () => {
          FB.init({
            appId,
            xfbml: false,
            status: true,
            version: 'v3.3',
          })
          loaded = true
          this.fbLoginStatus().then(resolve)
        }
        this.loadScript()
      })
    }
  }

  /**
   * Open Facebook login popup and ask user to integration with the application.
   * Note: this function is async
   *
   * @param authType - facebook auth flow type, not required, only use if you need to refresh the users token w/ 'reauthorize'
   * @returns a promise that will resolve once the user completes the facebook login process and the sdk queries facebook for that users instagram business accounts. Resolves with the tokens and instagram account ids.
   */
  async authInstagramAccounts(
    authType?: facebook.LoginOptions['auth_type'],
    removedScopes: string[] | null = null,
  ): Promise<AuthResponse> {
    let scopes = defaultScopes
    if (removedScopes) {
      scopes = defaultScopes.filter((s) => !removedScopes.includes(s))
    }
    const scopeString = scopes.join(',')
    const loginResponse = await this.fbLogin({ scope: scopeString, auth_type: authType })
    return this.getData(loginResponse)
  }

  async getInstagramAccounts(): Promise<InstagramAccount[]> {
    const igIds = await this.getInstagramIds()
    const response = await Promise.all(
      igIds.map((id) => this.fbApi<{ id: string; username: string }>(`/${id}?fields=id,username`)),
    )
    return response.map((r) => ({ platformId: r.id, username: r.username }))
  }

  private async getInstagramIds(): Promise<string[]> {
    const accountsResponse = await this.fbApi<FBAccountsResponse>('/me/accounts?fields=instagram_business_account')
    return accountsResponse.data
      .filter((i): i is FBAccountWithInstagram => !!i.instagram_business_account)
      .map((i) => i.instagram_business_account.id)
  }

  private async getData(loginResponse: facebook.StatusResponse): Promise<AuthResponse> {
    if (loginResponse.status !== 'connected') {
      return {
        status: loginResponse.status,
      }
    }
    if (!loginResponse.authResponse.accessToken) {
      return {
        status: 'not_authorized',
      }
    }

    try {
      const igIds = await this.getInstagramIds()
      return {
        status: 'connected',
        platformId: loginResponse.authResponse.userID,
        token: {
          value: loginResponse.authResponse.accessToken,
        },
        instagramAccounts: igIds.map((i) => ({ platformId: i })),
      }
    } catch (e) {
      return {
        status: 'error',
        error: e,
      }
    }
  }

  private loadScript() {
    if (document.getElementById(id)) return
    const js = document.createElement('script')
    js.id = id
    js.src = '//connect.facebook.net/en_US/sdk.js'
    const fjs = document.getElementsByTagName('script')[0] || document.body.lastChild
    const parent = fjs.parentNode || document.body
    parent.insertBefore(js, fjs)
  }

  private fbApi<T>(url: string, method?: 'get' | 'post' | 'delete'): Promise<T> {
    return new Promise((resolve, reject) => {
      const callback = (response: T & { error: Error }): void => {
        if (response.error) {
          reject(response.error)
        } else {
          resolve(response)
        }
      }

      if (method) {
        FB.api(url, method, {}, callback)
      } else {
        FB.api(url, callback)
      }
    })
  }

  private fbLogin(options: facebook.LoginOptions): Promise<facebook.StatusResponse> {
    return new Promise((resolve) => {
      FB.login(resolve, options)
    })
  }

  private fbLoginStatus(): Promise<facebook.StatusResponse> {
    return new Promise((resolve) => {
      FB.getLoginStatus(resolve)
    })
  }
}

const FacebookSDKInstance = new FacebookSDK()
export default FacebookSDKInstance
