import styles from './CollapsibleContainer.module.css'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down-minor.svg'
import { ReactComponent as CancelIcon } from '../../icons/cancel-minor.svg'
import React from 'react'

interface ContainerProps {
  title: string
  isOpen: boolean
  highlight?: boolean
  children?: React.ReactElement
  onContainerClick(): void
}
function CollapsibleContainer({
  title,
  isOpen,
  highlight,
  onContainerClick,
  children,
}: ContainerProps): React.ReactElement {
  return (
    <div className={highlight ? styles.containerHighlight : styles.container}>
      <div className={styles.header} onClick={onContainerClick}>
        <h4 className={styles.headerTitle}>{title}</h4>
        <div>{!isOpen ? <ChevronDownIcon className={styles.icon} /> : <CancelIcon className={styles.icon} />}</div>
      </div>
      {isOpen && <div className={styles.body}>{children}</div>}
    </div>
  )
}

export default CollapsibleContainer
