import React, { useState } from 'react'
import styles from './StoriesAndFeed.module.css'
import { ReactComponent as CheckmarkOutlineIcon } from '../../icons/checkmark-outline.svg'
import { ReactComponent as ErrorCircleIcon } from '../../icons/x-circle.svg'
import { HostCustomFieldFragment } from '../../generated/graphql'
import CustomField, { CustomFieldValue } from '../CustomField'

interface CustomFieldFormState {
  customFieldValues: CustomFieldValue[]
  customFieldErrors: CustomFieldValue[]
  customFieldTouched: CustomFieldValue[]
}
const defaultFormState = {
  customFieldValues: [],
  customFieldTouched: [],
  customFieldErrors: [],
}

function removeFieldFromErrors(errors: CustomFieldValue[], value: CustomFieldValue): CustomFieldValue[] {
  return errors.filter(e => e.id !== value.id)
}

interface ConfirmStoriesEmailAndCustomFieldsProps {
  apiEmailError: boolean
  userEmail: string | null
  userHandles: string | null
  isEditing: boolean
  handleCompleteSignUp(): void
  handleCloseEmailError(): void
  handleSetEditing(): void
  handleUpdateEmail(email: string): void
  customFields?: HostCustomFieldFragment[] | null
  handleUpdateCustomFields(values: CustomFieldValue[]): void
}

const apiEmailErrorMsg = 'Error updating email.'

function ConfirmStoriesEmailAndCustomFields({
  apiEmailError,
  userEmail,
  userHandles,
  isEditing,
  handleSetEditing,
  handleCompleteSignUp,
  handleUpdateEmail,
  handleCloseEmailError,
  customFields,
  handleUpdateCustomFields,
}: ConfirmStoriesEmailAndCustomFieldsProps): React.ReactElement {
  const [formState, setFormState] = useState<CustomFieldFormState>(defaultFormState)

  function handleCustomFieldChange(value: CustomFieldValue) {
    const { customFieldValues: values, customFieldTouched: touched } = { ...formState }
    let { customFieldErrors: errors } = { ...formState }
    const index = values.findIndex(v => v.id === value.id)
    const config = customFields?.find(c => c.id === value.id)

    //mark touched
    if (!touched.includes(value)) {
      touched.push(value)
    }

    //validate
    if (config?.required && !value.value) {
      errors.push(value)
    } else {
      errors = removeFieldFromErrors(errors, value)
    }

    //record value
    if (index > -1) {
      values[index] = value
    } else {
      values.push(value)
    }

    setFormState({
      ...formState,
      customFieldValues: values,
      customFieldTouched: touched,
      customFieldErrors: errors,
    })
    handleUpdateCustomFields(values)
  }

  function handleCustomFieldBlur(value: CustomFieldValue) {
    const { customFieldValues: values, customFieldTouched: touched } = { ...formState }
    let { customFieldErrors: errors } = { ...formState }
    const index = values.findIndex(v => v.id === value.id)
    const config = customFields?.find(c => c.id === value.id)

    //mark touched
    if (!touched.includes(value)) {
      touched.push(value)
    }

    //validate
    if (config?.required && !value.value) {
      errors.push(value)
    } else {
      errors = removeFieldFromErrors(errors, value)
    }

    //record value
    if (index > -1) {
      values[index] = value
    } else {
      values.push(value)
    }

    setFormState({
      ...formState,
      customFieldValues: values,
      customFieldTouched: touched,
      customFieldErrors: errors,
    })
    handleUpdateCustomFields(values)
  }

  function everyRequiredCustomFieldIsOk(): boolean {
    let result = true
    customFields
      ?.filter(f => !!f.required)
      .forEach(f => {
        if (
          formState.customFieldErrors.some(e => e.id === f.id) ||
          !formState.customFieldTouched.some(t => t.id === f.id)
        ) {
          result = false
        }
      })
    return result
  }

  return (
    <div>
      {apiEmailError ? (
        <div className={styles.connectionError}>
          <div className={styles.connectionErrorIcon}>
            <ErrorCircleIcon width={20} height={20} onClick={handleCloseEmailError} />
          </div>
          <div className={styles.connectionColumn}>
            <div className={styles.connectionErrorTitle}>Oops! That didn’t work</div>
            <div>{apiEmailErrorMsg}</div>
          </div>
        </div>
      ) : (
        <div className={styles.successContainer}>
          <div className={styles.successIcon}>
            <CheckmarkOutlineIcon width={20} height={20} />
          </div>
          <div className={styles.connectionColumn}>
            <div className={styles.successMessageContainer}>
              <span>Connected as </span>
              <span className={styles.handleNames}> {userHandles} </span>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className={styles.emailConfirmContainer}>
          <h4 className={styles.emailConfirmTitle}>Associated email</h4>
          {!isEditing ? (
            <div className={styles.emailConfirmEmailUpdate}>
              <span>{userEmail}</span>
              <span className={styles.emailUpdateText} onClick={handleSetEditing} role="button">
                Update
              </span>
            </div>
          ) : (
            <input
              autoFocus
              type="text"
              className={styles.formControl}
              id="email-input"
              placeholder="you@example.com"
              value={userEmail || ''}
              onChange={e => handleUpdateEmail(e.target.value)}
              onBlur={e => handleUpdateEmail(e.target.value)}
              name="email"
            />
          )}
        </div>
        {!!customFields && (
          <div>
            {customFields.map(f => (
              <CustomField
                key={`custom-field-${f.id}`}
                customField={f}
                value={formState.customFieldValues?.find(v => v.id === f.id) || null}
                touched={formState.customFieldTouched?.some(v => v.id === f.id) || false}
                error={formState.customFieldErrors?.some(v => v.id === f.id) || false}
                onChange={handleCustomFieldChange}
                onBlur={handleCustomFieldBlur}
              />
            ))}
          </div>
        )}
        <button
          className={styles.signUpButton}
          onClick={handleCompleteSignUp}
          disabled={!everyRequiredCustomFieldIsOk()}
        >
          Complete sign up
        </button>
      </div>
    </div>
  )
}

export default ConfirmStoriesEmailAndCustomFields
