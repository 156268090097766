import React, { useState, useEffect, useRef } from 'react'
import PhoneInput from 'react-phone-number-input'
import { DateParts, getLocaleDateOrder } from '../../utils/date-utils'
import { getCountryByCurrentTz } from '../../utils/tz-utils'
import { CustomFieldEnumType, HostCustomFieldFragment } from '../../generated/graphql'
import { COUNTRIES_LIST } from './countries'
import 'react-phone-number-input/style.css'
import styles from './CustomField.module.css'
import './phoneInputStyles.css'
import { STATES_LIST } from './states'
import { E164Number } from 'libphonenumber-js'

export interface CustomFieldValue {
  id: string
  value: string | null
  name: string
  error?: boolean | string
}

interface CustomFieldProps {
  customField: HostCustomFieldFragment
  value: CustomFieldValue | null
  touched: boolean
  error: boolean | string
  onChange(value: CustomFieldValue): void
  onBlur(value: CustomFieldValue): void
}

interface DateObj {
  day: string
  month: string
  year: string
}

function dateString(dateObj: DateObj): string {
  if (dateObj.day && dateObj.month && dateObj.year) {
    return `${dateObj.year}-${dateObj.month}-${dateObj.day}`
  }
  return ''
}

const dateStrLength: Record<DateParts, number> = {
  day: 2,
  month: 2,
  year: 4,
}

const dateOrder = getLocaleDateOrder()

const currentCountry = getCountryByCurrentTz()

function CustomField({
  customField,
  value,
  touched,
  error,
  onChange,
  onBlur,
}: CustomFieldProps): React.ReactElement | null {
  const { id, type, title, options, required } = customField
  const [date, setDate] = useState<DateObj>({ day: '', month: '', year: '' })
  const [phone, setPhone] = useState<string>('')
  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const refMap: Record<DateParts, HTMLInputElement | null> = {
      month: monthRef.current,
      day: dayRef.current,
      year: yearRef.current,
    }
    // find DateParts from currently active element
    const activeEntry = (Object.entries(refMap) as [DateParts, HTMLInputElement | null][]).find(
      ([_, v]) => !!v && document.activeElement === v,
    )
    const activeDatePart = activeEntry?.[0]
    // if one of the date fields is active and the text length of that field
    // is maxed out, focus next date element (if not last element)
    if (activeDatePart && date[activeDatePart].length === dateStrLength[activeDatePart]) {
      const currentIndex = dateOrder.indexOf(activeDatePart)
      if (currentIndex < dateOrder.length) {
        refMap[dateOrder[currentIndex + 1]]?.focus()
      }
    }
  }, [date])
  switch (type) {
    case CustomFieldEnumType.Address:
    case CustomFieldEnumType.Address_2:
    case CustomFieldEnumType.City:
    case CustomFieldEnumType.ZipCode:
    case CustomFieldEnumType.FirstName:
    case CustomFieldEnumType.LastName:
    case CustomFieldEnumType.Text: {
      return (
        <div className={styles.formGroup}>
          <label htmlFor={`${id}-input`} className={styles.formLabel}>
            {title}
            <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            <input
              type="text"
              className={error ? styles.formControlError : styles.formControl}
              id={`${id}-input`}
              value={value?.value || ''}
              onChange={(e) => onChange({ id, value: e.target.value, name: e.target.name })}
              onBlur={(e) => onBlur({ id, value: e.target.value, name: e.target.name })}
              name={`${id}-input`}
            />
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </label>
        </div>
      )
    }
    case CustomFieldEnumType.Phone: {
      const inputName = `${id}-phone-input`
      return (
        <div className={styles.formGroup}>
          <label htmlFor={`${id}-phone-input`} className={styles.formLabel}>
            {title}
            <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            <PhoneInput
              type="text"
              className={error ? styles.formControlErrorInput : styles.formControlPhoneInput}
              id={`${id}-phone-input`}
              value={(value?.value || '') as E164Number}
              defaultCountry={currentCountry}
              onChange={(value) => {
                onChange({ id, value: value as string, name: inputName })
                setPhone(value ?? '')
              }}
              onBlur={() => onBlur({ id, value: phone, name: inputName })}
              name={inputName}
            />
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </label>
        </div>
      )
    }
    case CustomFieldEnumType.Tiktok: {
      return (
        <div className={styles.formGroup}>
          <label htmlFor="tiktok-input" className={styles.formLabel}>
            {title}
            <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            <input
              type="text"
              className={error ? styles.formControlError : styles.formControl}
              id="tiktok-username-input"
              placeholder="@"
              value={value?.value || ''}
              onChange={(e) => onChange({ id, value: e.target.value, name: e.target.name })}
              onBlur={(e) => onBlur({ id, value: e.target.value, name: e.target.name })}
              name="tiktok-username"
            />
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </label>
        </div>
      )
    }
    case CustomFieldEnumType.Instagram: {
      return (
        <div className={styles.formGroup}>
          <label htmlFor="instagram-input" className={styles.formLabel}>
            {title}
            <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            <input
              type="text"
              className={error ? styles.formControlError : styles.formControl}
              id="instagram-username-input"
              placeholder="@"
              value={value?.value || ''}
              onChange={(e) => onChange({ id, value: e.target.value, name: e.target.name })}
              onBlur={(e) => onBlur({ id, value: e.target.value, name: e.target.name })}
              name="instagram-username"
            />
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </label>
        </div>
      )
    }
    case CustomFieldEnumType.Select: {
      return (
        <div className={styles.formGroup}>
          <label htmlFor={`${title}-input`} className={styles.formLabel}>
            {title}
            <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            <select
              className={error ? styles.formControlError : styles.formControl}
              id={`${title}-input`}
              placeholder="Select One"
              value={value?.value || ''}
              onChange={(e) => onChange({ id, value: e.target.value, name: e.target.name })}
              onBlur={(e) => onBlur({ id, value: e.target.value, name: e.target.name })}
              name={`${title}-input`}
            >
              <option key="default" value="">
                Select One...
              </option>
              {options &&
                options.map((o: string) => (
                  <option key={`option-${o}`} value={o}>
                    {o}
                  </option>
                ))}
            </select>
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </label>
        </div>
      )
    }
    case CustomFieldEnumType.State: {
      return (
        <div className={styles.formGroup}>
          <label htmlFor="state-input" className={styles.formLabel}>
            {title || 'State'}
            <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            <select
              className={error ? styles.formControlError : styles.formControl}
              id="state-input"
              placeholder="Select One"
              value={value?.value || ''}
              onChange={(e) => onChange({ id, value: e.target.value, name: e.target.name })}
              onBlur={(e) => onBlur({ id, value: e.target.value, name: e.target.name })}
              name="state-input"
            >
              <option key="default" value="">
                Select One...
              </option>
              {STATES_LIST &&
                STATES_LIST.map((o: string) => (
                  <option key={`option-${o.replaceAll(' ', '-')}`} value={o}>
                    {o}
                  </option>
                ))}
            </select>
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </label>
        </div>
      )
    }
    case CustomFieldEnumType.Country: {
      return (
        <div className={styles.formGroup}>
          <label htmlFor={`country-input-${id}`} className={styles.formLabel}>
            {title || 'Country'}
            <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            <select
              className={error ? styles.formControlError : styles.formControl}
              id={`country-input-${id}`}
              placeholder="Select One"
              value={value?.value || ''}
              onChange={(e) => onChange({ id, value: e.target.value, name: e.target.name })}
              onBlur={(e) => onBlur({ id, value: e.target.value, name: e.target.name })}
              name={`country-input-${id}`}
            >
              <option key="default" value="">
                Select One...
              </option>
              {COUNTRIES_LIST &&
                COUNTRIES_LIST.map((o: string) => (
                  <option key={`option-${o.replaceAll(' ', '-').replaceAll("'", '')}`} value={o}>
                    {o}
                  </option>
                ))}
            </select>
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </label>
        </div>
      )
    }
    case CustomFieldEnumType.Checkbox: {
      return (
        <div className={styles.formCheckBoxControl}>
          <div className={styles.formGroup}>
            <input
              type="checkbox"
              className={error ? styles.formControlError : styles.checkBox}
              id={`${title}-input`}
              checked={value?.value === 'true'}
              onChange={(e) => onChange({ id, value: e.target.checked ? 'true' : 'false', name: e.target.name })}
              onBlur={(e) => onBlur({ id, value: e.target.checked ? 'true' : 'false', name: e.target.name })}
              name={`${title}-input`}
            />
            <label className={styles.formLabel} htmlFor={`${title}-input`}>
              {title}
              <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            </label>
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </div>
        </div>
      )
    }
    case CustomFieldEnumType.SmsConsent: {
      return (
        <div className={styles.formCheckBoxControl}>
          <div className={styles.formGroup}>
            <input
              type="checkbox"
              className={error ? styles.formControlError : styles.checkBox}
              id={`${title}-input`}
              checked={value?.value === 'true'}
              onChange={(e) => onChange({ id, value: e.target.checked ? 'true' : 'false', name: e.target.name })}
              onBlur={(e) => onBlur({ id, value: e.target.checked ? 'true' : 'false', name: e.target.name })}
              name={`${title}-input`}
            />
            <label className={styles.formLabel} htmlFor={`${title}-input`}>
              {title}
              <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            </label>
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </div>
        </div>
      )
    }
    case CustomFieldEnumType.TermsConditions: {
      return (
        <div className={styles.formCheckBoxControl}>
          <div className={styles.formGroup}>
            <input
              type="checkbox"
              className={error ? styles.formControlError : styles.checkBox}
              id={`${title}-input`}
              checked={value?.value === 'true'}
              onChange={(e) => onChange({ id, value: e.target.checked ? 'true' : 'false', name: e.target.name })}
              onBlur={(e) => onBlur({ id, value: e.target.checked ? 'true' : 'false', name: e.target.name })}
              name={`${title}-input`}
            />
            <label
              className={styles.formLabel}
              htmlFor={`${title}-input`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </div>
        </div>
      )
    }
    case CustomFieldEnumType.Date: {
      const currentDate = dateString(date)
      const dayInput = (
        <input
          type="number"
          className={error ? styles.formControlError : styles.formControl}
          id={`${title}-input`}
          onChange={(e) => {
            const temp = { ...date, day: e.target.value }
            setDate(temp)
            onChange({ id, value: dateString(temp), name: e.target.name })
          }}
          onBlur={(e) => onBlur({ id, value: currentDate, name: e.target.name })}
          name={`${title}-input`}
          placeholder="DD"
          ref={dayRef}
          min="1"
          max="31"
        />
      )
      const monthInput = (
        <input
          type="number"
          className={error ? styles.formControlError : styles.formControl}
          id={`${title}-input`}
          onChange={(e) => {
            const { value, name } = e.target
            const temp = { ...date, month: value }
            setDate(temp)
            onChange({ id, value: dateString(temp), name })
          }}
          onBlur={(e) => onBlur({ id, value: currentDate, name: e.target.name })}
          name={`${title}-input`}
          placeholder="MM"
          ref={monthRef}
          min="1"
          max="12"
        />
      )
      const yearInput = (
        <input
          type="number"
          className={error ? styles.formControlError : styles.formControl}
          id={`${title}-input`}
          onChange={(e) => {
            const temp = { ...date, year: e.target.value }
            setDate(temp)
            onChange({ id, value: dateString(temp), name: e.target.name })
          }}
          onBlur={(e) => onBlur({ id, value: currentDate, name: e.target.name })}
          name={`${title}-input`}
          placeholder="YYYY"
          ref={yearRef}
          min="1900"
        />
      )
      const inputs = dateOrder.map((part) => {
        switch (part) {
          case 'year':
            return yearInput
          case 'month':
            return monthInput
          case 'day':
          default:
            return dayInput
        }
      })
      return (
        <div className={styles.formGroup}>
          <label htmlFor={`${title}-input`} className={styles.formLabel}>
            {title}
            <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
            <div role="group" aria-labelledby={`${title}-input`} className={styles.formGroupDate}>
              {inputs[0]}
              {'/'} {inputs[1]}
              {'/'} {inputs[2]}
            </div>
            {touched && error && <span className={styles.subtitleError}>{error}</span>}
          </label>
        </div>
      )
    }
    default: {
      return null
    }
  }
}

export default CustomField
