import React from 'react'
import styles from './Skeleton.module.css'

function Skeleton(): React.ReactElement {
  return (
    <div className={styles.skeletonRoot}>
      <div className={`${styles.skeleton} ${styles.avatar}`} />
      <div className={`${styles.skeleton} ${styles.programHost}`} />
      <div className={`${styles.skeleton} ${styles.formCopy}`} />
    </div>
  )
}

export default Skeleton
